import { AppUser } from '@/models/app-user-dto'
import { AuthService } from '@/services/auth-service'
import { LayoutService } from '@/services/layout-service'
import { Component, Vue } from 'vue-property-decorator'
import { UserManagerHelper } from './UserManagerHelper'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import UserDialog from '@/components/shared/Dialogs/UserDialog/UserDialog.vue'
import { headersItems } from './Constants'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'
import Commons from '@/components/shared/Helpers/commons'
import { AdministrationHelper } from './AdministrationHelper'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Alert,
    Confirm,
    UserDialog
  }
})
export default class Administration extends Vue {
  private layoutService = LayoutService.getInstance()
  private authService = AuthService.getInstance()
  private userManagerHelper = new UserManagerHelper()

  public users: AppUser[] = []
  public dialog = false
  public showConfirm = false
  public isLoading = false
  public errorMessages: string[] = []

  public alertMessages: string[] = []
  public showAlert = false
  public alertType: 'success' | 'error' | 'warning' = 'success'

  public headers = headersItems
  public showPasswordView = true
  public editedItem: AppUser = Administration.defaultAppUser()
  public selectedUserInitialSoinTypes: number[] = []

  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  private static defaultAppUser (): AppUser {
    return {
      claims: [],
      email: '',
      firstName: '',
      fullName: '',
      function: '',
      groupIdList: [],
      id: '',
      initials: '',
      isEnabled: true,
      lastName: '',
      mobile: '',
      soinsTypeIds: [],
      telephone: '',
      title: '',
      isHiddenFromHoursManagement: false
    }
  }

  public hasUserManagementAccess = false

  public mounted () {
    this.hasUserManagementAccess = ModuleAuthorisationManager.HasAccess('admin.userManagement')
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
    this.getAllUsers()
  }

  public destroyed () {
    this.layoutService.updateDrawerList([])
  }

  public getAllUsers () {
    this.isLoading = true
    this.authService.getAllUsers().then((users) => {
      this.users = users
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.isLoading = false
    })
  }

  public getColor (groupId: string) {
    return this.userManagerHelper.getColor(groupId)
  }

  public getGroupName (groupId: number) {
    return this.userManagerHelper.roles[groupId - 1]
  }

  public openUserDialog () {
    this.editedItem = Administration.defaultAppUser()
    this.selectedUserInitialSoinTypes = this.editedItem.soinsTypeIds.map(i => i)
    this.dialog = true
  }

  public togglePasswordView () {
    this.showPasswordView = !this.showPasswordView
  }

  public editItem (item: AppUser) {
    this.editedItem = item
    this.selectedUserInitialSoinTypes = this.editedItem.soinsTypeIds.map(i => i)
    this.showPasswordView = false
    this.dialog = true
  }

  public displayConfirmDialog (item: AppUser) {
    this.editedItem = item
    this.showConfirm = true
  }

  public async confirmCallback (value: any) {
    this.showConfirm = false
    if (value) {
      await this.deleteItem(this.editedItem)
    }
    this.editedItem = Administration.defaultAppUser()
  }

  private async deleteItem (item: any) {
    this.isLoading = true
    this.authService.deleteUser(item).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertErrorMessages(res)
    }).finally(() => {
      this.getAllUsers()
    })
  }

  public closeUserDialog () {
    this.showPasswordView = true
    this.isLoading = false
    this.editedItem = Administration.defaultAppUser()
    this.errorMessages = []
    this.dialog = false
  }

  public async save () {
    this.errorMessages = []
    this.isLoading = true

    if (this.editedItem.id) {
      await this.authService.updateUser(this.editedItem)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
    } else {
      await this.authService.register(this.editedItem)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
    }
    if (!this.errorMessages.length) {
      if (this.editedItem.id) {
        // check if the soins changed, we don't care about the order, so sort
        const userSoins = (this.editedItem.soinsTypeIds?.map(i => i) ?? []).sort((a, b) => a - b)
        const userInitialSoins = (this.selectedUserInitialSoinTypes?.map(i => i) ?? []).sort((a, b) => a - b)

        if (!Commons.checkSoinTypesMatch(userSoins, userInitialSoins)) {
          this.updateAlertWarningMessage('Modifier les soins d\'un intervenant peut entraîner des incohérences dans les plannings et dans l\'agenda.')
        }
      }
      this.closeUserDialog()
      this.getAllUsers()
    }

    this.isLoading = false
  }

  public hideAlert () {
    this.alertMessages = []
    this.showAlert = false
  }

  private updateAlertErrorMessages (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  private updateAlertWarningMessage (message: string) {
    this.alertMessages.push(message)
    this.alertType = 'warning'
    this.showAlert = true
  }
}
